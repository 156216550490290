import React, { useState, useEffect } from 'react';

import { graphql } from 'gatsby';

import { useIntl } from '../../../../../plugins/publicis-gatsby-plugin-i18n';
import Breadcrumb from '../../../../components/Breadcrumb/Breadcrumb';
import IntroPage from '../../../../components/ContentType/IntroPage/IntroPage';
import TextVisuelCTATwoCol from '../../../../components/ContentType/TextVisuelCTATwoCol/TextVisuelCTATwoCol';
import PageBanner from '../../../../components/PageBanner/PageBanner';
import ScrollPageNav from '../../../../components/ScrollPageNav/ScrollPageNav';
import Seo from '../../../../components/Seo/Seo';
import Layout from '../../../../Layout';
import ShapeBackground from '../../../../Molecules/ShapeBackground/ShapeBackground';
import TitlePage from '../../../../Molecules/TitlePage/TitlePage';
import { getImage } from '../../../../Utils/Utils';

import './styles.scss';

let classNames = require('classnames');

const ActivitiesPage = ({ data }) => {
  const intl = useIntl();
  const ourActivitiesData = data?.ourActivitiesData?.edges[0]?.node;
  const imagesArray = data?.allImages?.edges;

  const [blockList, setBlockList] = useState([]);
  const [innerNavList, setInnerNavList] = useState([]);

  let innerNavArr = [];
  let basicBlockCount = 0;
  let i = 1;

  const setInnerNav = (classname_, title, scrollEl) => {
    let t = '0' + i + '. ' + title?.replace(/<[^>]*>?/gm, '');
    innerNavArr.push({
      title: t,
      section: classname_,
      scrollEl: scrollEl
    });
    i++;
  };

  const getSectionName = (title) => {
    let secName = '';

    innerNavList.forEach((item) => {
      if (item.title === title) secName = item.section; return false;
    });
    return secName;
  };

  useEffect(() => {
    let myList = [];

    ourActivitiesData.relationships.field_blocs.forEach((block, index) => {
      if (block.__typename === 'block_content__basic') {
        setInnerNav('block_' + myList.length + '_section', block.field_title?.processed, 'section');
        myList.push(
          {
            title: block.field_title?.processed,
            visuel: getImage(imagesArray, block?.relationships?.field_image?.drupal_internal__mid)?.childImageSharp,
            subtitle: block.field_subtitle?.processed,
            text: block?.body?.processed,
            link: block?.field_link?.url,
            linkTitle: block?.field_link?.title,
            imageAlignment: block?.field_alignment,
            imageNoPadding: block?.field_no_padding
          }
        );
      }
    });

    setInnerNavList(innerNavArr);
    setBlockList(myList);
  }, []);

  return (
    <Layout imagesArray={imagesArray}>
      <Seo
        title={ourActivitiesData?.title}
        description={ourActivitiesData?.body?.processed}
      />
      <div className={classNames('page_template', 'page_activities')}>
        <ScrollPageNav
          data={innerNavList}
        />
        <PageBanner visuel={getImage(imagesArray, ourActivitiesData?.relationships?.field_image?.drupal_internal__mid)?.childImageSharp?.fluid} scroll={true}>
          <div className="wrapper_page">
            <Breadcrumb
              page_banner={true}
              data={{
                //grandParentPage: { title: 'Acceuil', url: '/' },
                parentPage: { title: intl.formatMessage({ id: 'group.label' }), url: '' },
                currentPage: { title: ourActivitiesData.title, url: '/' + ourActivitiesData.langcode + ourActivitiesData.path.alias },
                locale: ourActivitiesData.langcode
              }}
            />
            <TitlePage
              color="color_white"
              title={ourActivitiesData.title}
              description={ourActivitiesData?.body?.processed}
            />
          </div>
        </PageBanner>
        {ourActivitiesData.relationships.field_blocs.map((block, i) => {
          if (block.__typename === 'block_content__block_texte') {
            //setInnerNav('intro_page_component', 'hide');
            return (
              <IntroPage
                key={i}
                customClass="topZIndex"
                title={block?.title?.processed === undefined && null}
                visuel={getImage(imagesArray, block?.relationships?.field_image?.drupal_internal__mid)?.childImageSharp}
                size="border" //wrapper
                text={block?.body?.processed}
              />
            );
          }

          if (blockList.length > 0 && basicBlockCount === 0) {
            basicBlockCount++;
            return (<section className={classNames('section_content_multiple')} key={i}>
              <ShapeBackground
                top="right"
                color="catskill_white"
                bottomInfinite={true}
              >
                <div className="wrapper_page">
                  {
                    blockList.map((block, index) => {
                      //setInnerNav('block_' + index + '_section', block.title);
                      return (
                        <section className={classNames('section_content', 'block_' + index + '_section')} key={index}>
                          <TextVisuelCTATwoCol

                            extremeLeft={(block?.imageNoPadding && block?.imageAlignment === 'left') ? true : false}
                            extremeRight={(block?.imageNoPadding && block?.imageAlignment === 'left') ? false : true}
                            orderInverse={block?.imageAlignment === 'right' ? true : false}

                            visuel={block?.visuel}
                            visuelSize='half'
                            title={{
                              titleSize: 'h2',
                              titleText: block?.title,
                              subtitle: block?.subtitle
                            }}
                            text={block.text}
                            cta={{
                              ctaText: block?.linkTitle,
                              ctaLink: block?.link
                            }}
                          />
                        </section>
                      );
                    })
                  }
                </div>
              </ShapeBackground>
            </section>
            );
          }
        })}

      </div>
    </Layout>
  );
};

export const query = graphql`
query OurActivitiesTemplateQuery($locale: String!, $slug: String!, $imagesID: [Int!]) {
  ourActivitiesData: allNodePage(
    filter: {langcode: {eq: $locale}, path: {alias: {eq: $slug}}}
  ) {
    edges {
      node {
        path {
          alias
        }
        title
        langcode
        relationships {
          field_image {
            drupal_internal__mid
          }
          field_blocs {
            __typename
            ... on Node {
              ... on block_content__basic {
                relationships {
                  field_image {
                    drupal_internal__mid
                  }
                }
                body {
                  processed
                }
                field_alignment
                field_link {
                  title
                  url
                }
                field_no_padding
                field_subtitle {
                  processed
                }
                field_title {
                  processed
                }
              }
              ... on block_content__block_texte {
                relationships {
                  field_image {
                    drupal_internal__mid
                  }
                }
                body {
                  processed
                }
                field_title {
                  processed
                }
              }
            }
          }
        }
        field_type
        body {
          processed
        }
      }
    }
  }
  allImages: allMediaImage(
    filter: {langcode: {eq: "fr"}, drupal_internal__mid: {in: $imagesID}}
  ) {
    edges {
      node {
        drupal_internal__mid
        relationships {
          field_media_image {
            localFile {
              publicURL
              childImageSharp {
                fluid {
                  ...GatsbyImageSharpFluid
                }
                fixed(width: 332, height: 332) {
                  ...GatsbyImageSharpFixed
                }
              }
            }
          }
        }
        langcode
      }
    }
  }

  file(relativePath: {eq: "slider-image.png"}) {
    childImageSharp {
      fluid {
        ...GatsbyImageSharpFluid
      }
    }
  }
}
`;

export default ActivitiesPage;
